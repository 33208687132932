import pathToRegexp from 'path-to-regexp';

type Params = { [key: string]: number | string | boolean };

// Databases
const DATABASES_ROOT = '/databases';
const DATABASES_SUB_BDB_VIEW = `${DATABASES_ROOT}/:databaseId/subscription/:subscriptionId/view-bdb`;
const DATABASES_SUB_CRDB_VIEW = `${DATABASES_ROOT}/:crdbId/:selectedRegionName/subscription/:subscriptionId/view-crdb`;
const UPGRADE_DATABASE = `${DATABASES_ROOT}/upgrade/:subscriptionId`;

// Subscriptions
const SUBSCRIPTIONS_ROOT = '/subscriptions';
const SUBSCRIPTION_ROOT = `${SUBSCRIPTIONS_ROOT}/subscription/:subscriptionId`;
const SUBSCRIPTION_DATABASE = `${SUBSCRIPTION_ROOT}/bdb`;
const SUBSCRIPTION_CONNECTIVITY = `${SUBSCRIPTION_ROOT}/connectivity`;
const SUBSCRIPTION_BDB_VIEW = `${SUBSCRIPTION_ROOT}/bdb-view/:databaseId`;
const SUBSCRIPTION_CRDB_VIEW = `${SUBSCRIPTION_ROOT}/crdb-view/:crdbId/:selectedRegionName`;
export const CRDB_GLOBAL_NAME = 'global';

const VPC_PEERING_ROOT = 'vpc-peering';
// change subscription plan
const SUBSCRIPTION_CHANGE_PLAN = '/subscription/:subscriptionId/change-plan';

// data access control
const DATA_ACCESS_CONTROL_ROOT = '/data-access-control';
// service log
const SERVICE_LOG_ROOT = '/service-log';
// billing history
const BILLINGS_AND_PAYMENTS = '/billing-and-payments';
// usage report
const USAGE_REPORT = '/usage-report';
// access management
const ACCESS_MANAGEMENT_ROOT = '/access-management';
// Create subscription
export const CREATE_SUBSCRIPTION_PLAN_ESSENTIAL = 'essential';
export const CREATE_SUBSCRIPTION_PLAN_FLEX = 'redisFlex';
export const CREATE_SUBSCRIPTION_PLAN_PRO = 'pro';
export const CREATE_SUBSCRIPTION_PLAN_ANNUAL = 'annual';

const CREATE_SUBSCRIPTION = '/add-subscription';
const CREATE_SUBSCRIPTION_ESSENTIAL = `${CREATE_SUBSCRIPTION}/${CREATE_SUBSCRIPTION_PLAN_ESSENTIAL}`;
const CREATE_SUBSCRIPTION_FLEX = `${CREATE_SUBSCRIPTION}/${CREATE_SUBSCRIPTION_PLAN_FLEX}`;
const CREATE_SUBSCRIPTION_PRO = `${CREATE_SUBSCRIPTION}/${CREATE_SUBSCRIPTION_PLAN_PRO}/sub/rcp/new`;
const CREATE_SUBSCRIPTION_PRO_SETUP = `${CREATE_SUBSCRIPTION_PRO}/setup`;
const CREATE_SUBSCRIPTION_PRO_SIZING = `${CREATE_SUBSCRIPTION_PRO}/sizing`;
const CREATE_SUBSCRIPTION_PRO_CREATE = `${CREATE_SUBSCRIPTION_PRO}/create`;
const CREATE_SUBSCRIPTION_ANNUAL = `${CREATE_SUBSCRIPTION}/${CREATE_SUBSCRIPTION_PLAN_ANNUAL}`;
// Account Settings
const ACCOUNT_SETTINGS = '/account-settings';

// Download center
const DOWNLOAD_CENTER = '/rlec-downloads';

const AA_SUBSCRIPTION = `${SUBSCRIPTION_ROOT}/active-active`;
const AA_SUBSCRIPTION_DETAILS = `${AA_SUBSCRIPTION}/:selectedRegionName`;

const PSC_ROOT = `${SUBSCRIPTION_CONNECTIVITY}/private-service-connect`;
const AA_PSC_ROOT = `${AA_SUBSCRIPTION_DETAILS}/connectivity/private-service-connect`;

// New Customer
const NEW_CUSTOMER = '/new-customer';

export const routes = {
  root: '/',
  login: '/login',
  logout: '/logout',
  maintenance: '/maintenance',
  databases: {
    root: DATABASES_ROOT,
    viewBdb: {
      root: DATABASES_SUB_BDB_VIEW,
      configuration: `${DATABASES_SUB_BDB_VIEW}/configuration`,
      metric: `${DATABASES_SUB_BDB_VIEW}/metric`,
      slowLogs: `${DATABASES_SUB_BDB_VIEW}/slowlogs`,
      tags: `${DATABASES_SUB_BDB_VIEW}/tags`,
      rdi: `${DATABASES_SUB_BDB_VIEW}/rdi`
    },
    viewCrdb: {
      root: DATABASES_SUB_CRDB_VIEW,
      configuration: `${DATABASES_SUB_CRDB_VIEW}/configuration`,
      metric: `${DATABASES_SUB_CRDB_VIEW}/metric`,
      slowLogs: `${DATABASES_SUB_CRDB_VIEW}/slowlogs`,
      tags: `${DATABASES_SUB_CRDB_VIEW}/tags`
    },
    upgrade: UPGRADE_DATABASE
  },
  subscriptions: {
    root: SUBSCRIPTIONS_ROOT,
    subscription: {
      root: SUBSCRIPTION_ROOT,
      db: {
        root: SUBSCRIPTION_DATABASE,
        createBdb: `${SUBSCRIPTION_DATABASE}/create`,
        createCrdb: `${AA_SUBSCRIPTION}/create`,
        bdbView: SUBSCRIPTION_BDB_VIEW,
        crdbView: SUBSCRIPTION_CRDB_VIEW,
        bdbTabs: {
          configuration: `${SUBSCRIPTION_BDB_VIEW}/configuration`,
          metric: `${SUBSCRIPTION_BDB_VIEW}/metric`,
          slowLogs: `${SUBSCRIPTION_BDB_VIEW}/slowlogs`,
          tags: `${SUBSCRIPTION_BDB_VIEW}/tags`,
          rdi: `${SUBSCRIPTION_BDB_VIEW}/rdi`
        },
        crdbTabs: {
          configuration: `${SUBSCRIPTION_CRDB_VIEW}/configuration`,
          metric: `${SUBSCRIPTION_CRDB_VIEW}/metric`,
          slowLogs: `${SUBSCRIPTION_CRDB_VIEW}/slowlogs`,
          tags: `${SUBSCRIPTION_CRDB_VIEW}/tags`
        }
      },
      overview: `${SUBSCRIPTION_ROOT}/overview`,
      connectivity: {
        root: SUBSCRIPTION_CONNECTIVITY,
        vpcPeering: `${SUBSCRIPTION_CONNECTIVITY}/${VPC_PEERING_ROOT}`,
        allowList: `${SUBSCRIPTION_CONNECTIVITY}/allow-list`,
        psc: {
          root: PSC_ROOT,
          endpointDetails: `${PSC_ROOT}/endpoint-details`,
          updateEndpointDetails: `${PSC_ROOT}/endpoint-details/:pscEndpointId`,
          addConnections: `${PSC_ROOT}/add-connections/:pscEndpointId`
        },
        tgw: `${SUBSCRIPTION_CONNECTIVITY}/tgw`
      },
      aa: {
        root: AA_SUBSCRIPTION_DETAILS,
        connectivity: {
          root: `${AA_SUBSCRIPTION_DETAILS}/connectivity`,
          vpcPeering: `${AA_SUBSCRIPTION_DETAILS}/connectivity/vpc-peering`,
          tgw: `${AA_SUBSCRIPTION_DETAILS}/connectivity/tgw`,
          psc: {
            root: AA_PSC_ROOT,
            endpointDetails: `${AA_PSC_ROOT}/endpoint-details`,
            updateEndpointDetails: `${AA_PSC_ROOT}/endpoint-details/:pscEndpointId`,
            addConnections: `${AA_PSC_ROOT}/add-connections/:pscEndpointId`
          }
        },
        regions: `${AA_SUBSCRIPTION_DETAILS}/regions`
      },
      security: `${SUBSCRIPTION_ROOT}/security`
    }
  },
  dataAccessControl: {
    root: DATA_ACCESS_CONTROL_ROOT,
    usersTab: `${DATA_ACCESS_CONTROL_ROOT}/users`,
    rolesTab: `${DATA_ACCESS_CONTROL_ROOT}/roles`,
    redisAclsTab: `${DATA_ACCESS_CONTROL_ROOT}/redis-acls`
  },
  accountSettings: {
    root: ACCOUNT_SETTINGS,
    account: `${ACCOUNT_SETTINGS}/account`,
    cloudAccounts: `${ACCOUNT_SETTINGS}/cloud-accounts`,
    integrations: `${ACCOUNT_SETTINGS}/integrations`
  },
  accessManagement: {
    root: ACCESS_MANAGEMENT_ROOT,
    teamsTab: `${ACCESS_MANAGEMENT_ROOT}/teams`,
    apiKeysTab: `${ACCESS_MANAGEMENT_ROOT}/api-keys`,
    singleSignOn: `${ACCESS_MANAGEMENT_ROOT}/single-sign-on`
  },
  serviceLogs: {
    root: SERVICE_LOG_ROOT,
    system: `${SERVICE_LOG_ROOT}/system`,
    session: `${SERVICE_LOG_ROOT}/session`
  },
  billingHistory: {
    root: BILLINGS_AND_PAYMENTS,
    billings: `${BILLINGS_AND_PAYMENTS}/billing-history`,
    payments: `${BILLINGS_AND_PAYMENTS}/payment-methods`,
    credits: `${BILLINGS_AND_PAYMENTS}/credits`
  },
  usageReport: {
    root: USAGE_REPORT,
    memoryUsage: `${USAGE_REPORT}/memory`,
    shardUsage: `${USAGE_REPORT}/shard`
  },
  createSubscription: {
    root: CREATE_SUBSCRIPTION,
    essential: CREATE_SUBSCRIPTION_ESSENTIAL,
    flex: CREATE_SUBSCRIPTION_FLEX,
    pro: {
      root: `${CREATE_SUBSCRIPTION_PRO}/:id`,
      setup: CREATE_SUBSCRIPTION_PRO_SETUP,
      sizing: CREATE_SUBSCRIPTION_PRO_SIZING,
      create: CREATE_SUBSCRIPTION_PRO_CREATE
    },
    annual: CREATE_SUBSCRIPTION_ANNUAL
  },
  changeSubscriptionPlan: {
    root: SUBSCRIPTION_CHANGE_PLAN
  },
  newCustomer: {
    root: NEW_CUSTOMER,
    newDatabase: `${NEW_CUSTOMER}/new-database`
  },
  // This is a temporary placeholder, it is used for demonstrate the support for loading MFE.
  kyc: '/kyc',
  settings: '/settings',
  userProfile: '/profile',
  downloadCenter: DOWNLOAD_CENTER,
  socialConfirmMigration: '/social-confirm-migration',
  samlConfirmMigration: '/saml-confirm-migration',
  missingEmailSocialLogin: '/social-login-error',
  forgotPassword: '/forgot-password',
  activateResetPassword: '/activate-reset-password',
  resetPassword: '/set-password', // reset === set to avoid user confusion
  activateInvitation: '/activate-invitation',
  signUp: '/sign-up',
  saml: '/saml-login',
  oktaCallback: '/implicit/callback',
  activateRegistration: '/activate-registration',
  marketplaceAccountMapping: '/marketplace-account-mapping',
  marketplaceFreeTrial: '/marketplace-free-trial',
  riCloud: '/ri-cloud'
};

export const ROOTS: string[] = [];

// Gets all root paths ones as the app is up and updates the ROOT constant
// eslint-disable-next-line func-names
(function () {
  Object.assign(
    {},
    ...(function flatten(routeObject) {
      return [].concat(
        ...Object.keys(routeObject).map((key) => {
          if (typeof routeObject[key] === 'object') {
            if (routeObject[key].root) {
              ROOTS.push(routeObject[key].root);
            }

            return flatten(routeObject[key]);
          }

          return { [key]: routeObject[key] };
        })
      );
    })(routes)
  );
})();

export const buildPath = (route: string, params: Params | undefined = undefined) => {
  const toPath = pathToRegexp.compile(route);

  return toPath(params);
};
