import { buildMaintenanceWindow } from 'screens/CreateSubscription/SubscriptionTypes/Pro/utils/buildMaintenanceWindow';
import { IPlainObject } from '../../../types/interfaces';
import { SubscriptionPostPayload } from '../createSubscription.types';
import { Crdb, CrdbInstance } from '../../databases/databases.types';
import {
  ExtendedDatabasesToCreate,
  Region
} from '../../../screens/CreateSubscription/SubscriptionTypes/Pro/Pro.types';
import { CloudProviders } from '../../../components/CloudProvider/CloudProvider.types';

export enum ProActionTypes {
  POST_PRO_PLAN_REQUEST = 'POST_PRO_PLAN_REQUEST',
  POST_PRO_PLAN_SUCCESS = 'POST_PRO_PLAN_SUCCESS',
  POST_PRO_PLAN_FAILURE = 'POST_PRO_PLAN_FAILURE',

  POST_PRO_SUBSCRIPTION_REQUEST = 'POST_PRO_SUBSCRIPTION_REQUEST',
  POST_PRO_SUBSCRIPTION_SUCCESS = 'POST_PRO_SUBSCRIPTION_SUCCESS',
  POST_PRO_SUBSCRIPTION_FAILURE = 'POST_PRO_SUBSCRIPTION_FAILURE',

  CALCULATE_PRICE_REQUEST = 'CALCULATE_PRICE_REQUEST',
  CALCULATE_PRICE_SUCCESS = 'CALCULATE_PRICE_SUCCESS',
  CALCULATE_PRICE_FAILURE = 'CALCULATE_PRICE_FAILURE',

  RESET_PRO_STATE = 'RESET_PRO_STATE',
  CLEAR_PRO_PLAN = 'CLEAR_PRO_PLAN',

  CREATE_AA_SUBSCRIPTION_REQUEST = 'CREATE_AA_SUBSCRIPTION_REQUEST',
  CREATE_AA_SUBSCRIPTION_SUCCESS = 'CREATE_AA_SUBSCRIPTION_SUCCESS',
  CREATE_AA_SUBSCRIPTION_FAILURE = 'CREATE_AA_SUBSCRIPTION_FAILURE',
  CREATE_ONLY_AA_SUBSCRIPTION_REQUEST = 'CREATE_ONLY_AA_SUBSCRIPTION_REQUEST',
  CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS = 'CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS',
  POST_CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS = 'POST_CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS',
  EXECUTE_AA_SUBSCRIPTION_REQUEST = 'EXECUTE_AA_SUBSCRIPTION_REQUEST'
}

export interface PostProPlanRequestActionPayload {
  subsRcp: SubsRcp;
  openSupportPageAction: () => void;
  isSupportPageLoading?: boolean;
}
export interface PostProPlanRequestAction {
  type: ProActionTypes.POST_PRO_PLAN_REQUEST;
  payload: PostProPlanRequestActionPayload;
}

export interface PostProPlanSuccessAction {
  type: ProActionTypes.POST_PRO_PLAN_SUCCESS;
  payload: PostProPlanSuccessPayload;
}

export interface PostProPlanFailureAction {
  type: ProActionTypes.POST_PRO_PLAN_FAILURE;
}

export interface ClearProPlanAction {
  type: ProActionTypes.CLEAR_PRO_PLAN;
}

interface PostProSubscriptionExtendedPayload extends SubscriptionPostPayload {
  onSuccess: (subscriptionId: number) => void;
}

export interface PostProSubscriptionRequestAction {
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_REQUEST;
  payload: PostProSubscriptionExtendedPayload;
}

export interface PostProSubscriptionSuccessAction {
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_SUCCESS;
}

export interface PostProSubscriptionFailureAction {
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_FAILURE;
}

export interface CalculatePriceRequestAction {
  type: ProActionTypes.CALCULATE_PRICE_REQUEST;
  payload: CalculatePriceRequestPayload;
}

export interface CalculatePriceSuccessAction {
  type: ProActionTypes.CALCULATE_PRICE_SUCCESS;
  payload: CalculatePriceSuccessPayload;
}

export interface CalculatePriceFailureAction {
  type: ProActionTypes.CALCULATE_PRICE_FAILURE;
  payload: string;
}

export interface ResetProStateAction {
  type: ProActionTypes.RESET_PRO_STATE;
}

export interface CreateAASubscriptionRequestAction {
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_REQUEST;
  payload: AASubscriptionRequestPayload;
}

export interface CreateAASubscriptionSuccessAction {
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_SUCCESS;
}

export interface CreateAASubscriptionFailureAction {
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_FAILURE;
  payload: string;
}

export interface CreateOnlyAASubscriptionRequestAction {
  type: ProActionTypes.CREATE_ONLY_AA_SUBSCRIPTION_REQUEST;
  payload: AASubscriptionRequestPayload;
}

export interface PostCreateOnlyAASubscriptionSuccessAction {
  type: ProActionTypes.CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS;
  payload: PostAaChangeRequestSuccessPayload;
}

export interface ExecuteAASubscriptionRequestAction {
  type: ProActionTypes.EXECUTE_AA_SUBSCRIPTION_REQUEST;
  payload: ExecuteAaSubsCallParams;
}

export type ProActions =
  | PostProPlanRequestAction
  | PostProPlanSuccessAction
  | PostProPlanFailureAction
  | PostProSubscriptionRequestAction
  | PostProSubscriptionSuccessAction
  | PostProSubscriptionFailureAction
  | ResetProStateAction
  | CalculatePriceRequestAction
  | CalculatePriceSuccessAction
  | CalculatePriceFailureAction
  | ClearProPlanAction
  | CreateAASubscriptionFailureAction
  | CreateAASubscriptionRequestAction
  | CreateAASubscriptionSuccessAction
  | CreateOnlyAASubscriptionRequestAction
  | PostCreateOnlyAASubscriptionSuccessAction
  | ExecuteAASubscriptionRequestAction;

export interface ProPlanState extends BaseState {
  data: null | RcpPlanStatusResponse;
}

export interface AaChangeRequestState extends BaseState {
  data: null | AaChangeRequestPayload;
}

export interface EstimatedPriceState extends BaseState {
  data: CalculatePriceSuccessPayload;
}

export interface PostProPlanSuccessPayload {
  proPlan: RcpPlanStatusResponse;
  rcpId: number;
}
export interface PostAaChangeRequestSuccessPayload {
  aaChangeRequestPayload: AaChangeRequestPayload;
}

export interface CalculatePriceSuccessPayload {
  shardTypePricingList?: ShardTypePricing[];
  price: number;
  comparedPrice?: number;
  comparedSize?: number;
}

export interface AASubscriptionRequestPayload {
  aaChangeBodyPayload: AaChangeRequestPayload;
  paymentId: string;
  subscriptionName: string;
  isMarketplace: boolean;
  maintenanceWindow?: ReturnType<typeof buildMaintenanceWindow>;
  isShouldExecute?: boolean;
  onSuccess?: (subscriptionId: number) => void;
}

export type AAPlanStatus =
  | 'planning'
  | 'plan_done_provision_needed'
  | 'initial'
  | 'plan_failed'
  | 'execution_done_with_errors'
  | 'plan_done_infrastructure_sufficient'
  | 'executing'
  | 'execution_failed'
  | 'execution_done'
  | 'expired'
  | 'plan_done_encryption_key_pending';

export interface CalculatePriceRequestPayload {
  cloud_account: CloudAccount;
  databases_to_create: ExtendedDatabasesToCreate[];
  rof: boolean;
  cluster_version: string;
  provider: CloudProviders;
  region?: string;
  regions?: Region[];
  isAA: boolean;
  minimalPricingList: SubsMinimalPricing[];
}

export interface CrdbsToCreate
  extends Omit<
    Crdb,
    | 'id'
    | 'status'
    | 'shard_key_regex'
    | 'import_status'
    | 'crdb_instances'
    | 'sharding_type'
    | 'creation_date'
    | 'last_changed_date'
  > {
  id?: number;
  status: null;
  quantity: number;
  crdb_instances: Omit<
    CrdbInstance,
    | 'usage'
    | 'status'
    | 'backup_interval'
    | 'backup_interval_offset'
    | 'data_persistence'
    | 'backup_status'
    | 'backup_failure_reason'
    | 'backup_progress'
    | 'last_backup_time'
    | 'bdb_alerts'
  >[];
}

interface Networking {
  cidr: string;
  vpc_id?: string;
  subnets: IPlainObject[];
}

interface AARegion {
  region_id: number;
  cloud_account_id?: number;
  networking: Networking;
  nodes: RcpNode[];
  multi_az?: boolean;
  rof: boolean;
  rcp_plan_metadata?: IPlainObject;
  redis_version?: string;
  region_name?: string;
}

export interface ExecuteAaSubsCallParams {
  aaChangeRequestId: number;
  subscriptionName?: string;
  paymentId?: string;
  isMarketplace?: boolean;
  onSuccess?: (subscriptionId: number) => void;
}

interface RegionsToChange {
  change_type: string;
  regions?: Partial<AARegion>[];
  crdbs_to_update?: Crdb[];
}

interface DefaultRegionConfig {
  redis_version: string;
}

export interface AAChanges {
  crdbs_to_create?: CrdbsToCreate[];
  crdbs_to_update?: Crdb[];
  crdbs_to_delete?: number[];
  regions_to_change?: RegionsToChange;
  default_region_config?: DefaultRegionConfig;
}

interface AaBlueprint {
  crdbs: IPlainObject[];
  crdb_regions: CrdbRegion[];
  monthly_instances_price: number;
  monthly_databases_price: number;
  monthly_storage_price: number;
  fixed_price?: number;
}

export interface AaChangeRequestPayload {
  aa_blueprint?: AaBlueprint;
  status?: AAPlanStatus;
  request_id?: number;
  rcp_type: RcpType;
  changes: AAChanges;
  subscription_id?: number;
  id?: number;
  optimization?: boolean;
}
